import type { CursorCollection } from "@smakecloud/smake-use";
import type {
    Logo,
    ExpandedLogo,
    UpdateLogoRequest,
    LogoFileData,
    PostS3Request,
    PostS3Response,
} from "~/lib/api/types/logos";
import type { ProductionMethod } from "~/lib/api/types/productionMethods";
import axios from "axios";

export async function list(params?: {
    cursor?: string;
    per_page?: number;
    search?: string;
    filter?: Record<string, string | number | boolean>;
}): Promise<CursorCollection<Logo>> {
    return (await axios.get<CursorCollection<Logo>>("logos", { params })).data;
}

export async function get(id: number): Promise<ExpandedLogo> {
    return (await axios.get<ExpandedLogo>(`logos/${id}`)).data;
}

export async function getPreview(logoId: number, logo: UpdateLogoRequest) {
    return (
        await axios.post<{ width: number; height: number; content: string }>(
            `logos/${logoId}/preview`,
            logo,
        )
    ).data;
}

export async function getHighResPreview(
    logoId: number,
    logo: UpdateLogoRequest,
) {
    return (
        await axios.post<{ width: number; height: number; content: string }>(
            `logos/${logoId}/high-res-preview`,
            logo,
        )
    ).data;
}

export async function create(
    productionMethod: ProductionMethod,
    temporaryFile: LogoFileData,
) {
    return (
        await axios.post<Logo>("logos", {
            production_method: productionMethod,
            file: temporaryFile,
        })
    ).data;
}

export async function update(
    logoId: number,
    logo: UpdateLogoRequest,
): Promise<ExpandedLogo> {
    return (await axios.patch<ExpandedLogo>(`logos/${logoId}`, logo)).data;
}

export function destroy(logoId: number): Promise<unknown> {
    /* TODO: logic needs to be adjusted once backend is implemented (possibly) */

    return axios.delete(`logos/${logoId}`);
}

export async function postS3(request: PostS3Request): Promise<PostS3Response> {
    return (await axios.post<PostS3Response>("/media/post-s3", request)).data;
}
